<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="onClose"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10" class="text-md-left d-flex flex-column flex-md-row justify-space-between">
              <atoms-title>{{ pureEdit ? 'Edit' : isEdit ? 'Edit' : 'Detail' }} Pekerja</atoms-title>
              <atoms-button
                v-if="!pureEdit"
                @click="
                  () => {
                    isEdit = !isEdit
                    if (!isEdit) {
                      reset()
                    }
                    $showDialog({
                      title: 'Perhatian',
                      body: `Anda ${!isEdit ? 'mematikan' : 'menyalakan'} mode edit`,
                    })
                  }
                "
                class="primary mt-4 mt-md-0"
                >{{ isEdit ? 'Matikan Edit' : 'Aktifkan Edit' }}</atoms-button
              >
            </v-col>

            <v-col cols="10" align="start">
              <div class="d-flex flex-column flex-md-row align-center justify-space-between">
                <div class="d-flex pt-4 flex-column flex-md-row align-center">
                  <div>
                    <!-- PHOTO -->
                    <v-hover>
                      <v-avatar slot-scope="{ hover }" size="200" color="grey">
                        <atoms-image
                          v-if="photo && photo.url"
                          class="rounded-lg"
                          :src="(photo && photo.url) || '/media/person-example.webp'"
                        >
                          <v-overlay :value="$vuetify.breakpoint.smAndDown ? true : hover" opacity="0.5" absolute>
                            <div
                              class="pa-12"
                              :style="{
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                              }"
                            >
                              <atoms-text v-if="isEdit" @click="openUploader('uploaderSelf')" class="font-weight-medium"
                                ><v-icon small left> mdi-camera </v-icon>Ganti Foto</atoms-text
                              >

                              <atoms-text
                                @click="
                                  photo &&
                                    photo.url &&
                                    $viewerApi({
                                      options: {
                                        toolbar: false,
                                      },
                                      images: [photo.url],
                                    })
                                "
                                class="font-weight-medium mt-4"
                                ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                              >
                            </div>
                          </v-overlay></atoms-image
                        >
                        <v-icon v-else x-large>mdi-account</v-icon>
                      </v-avatar>
                    </v-hover>
                    <input ref="uploaderSelf" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                  </div>

                  <div
                    class="ml-md-8 d-flex py-4 align-center align-md-start flex-column justify-center text-center text-md-left"
                  >
                    <atoms-title :h2="true" class="font-weight-bold"
                      >{{ data.fullName }}
                      <v-chip v-if="data.captain" small class="primary lighten-5 primary--text rounded-lg"
                        >Kapten</v-chip
                      ></atoms-title
                    >
                    <atoms-text class="primary--text">{{ data.email }}</atoms-text>
                    <atoms-text>Terdaftar Pada {{ $moment(data._createdDate).format('YYYY-MM-DD') }}</atoms-text>
                    <atoms-text>Approval Oleh {{ data.setStatusBy || '-' }}</atoms-text>
                    <atoms-text
                      >Referral {{ (data.workerReferralData && data.workerReferralData.title) || '-' }}</atoms-text
                    >
                  </div>
                </div>
                <atoms-button :disabled="!isEdit" @click="openUploader('uploaderSelf')" class="primary"
                  >Ganti Foto</atoms-button
                >
              </div>
            </v-col>
            <v-col cols="10">
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, i) in 1" :key="i">
                  <v-expansion-panel-header @click="getHistory"> Riwayat Pekerjaan </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="historyWorkItems && historyWorkItems.length > 0 && !mainLoading">
                      <div v-for="({ client, startDate }, i) in historyWorkItems" :key="i" class="py-5 px-1">
                        <v-row align="start">
                          <v-col cols="8" lg="10" class="text-start">
                            <atoms-text class="font-weight-bold">{{
                              (client && client.name) || 'Perusahaan ~'
                            }}</atoms-text>
                          </v-col>
                          <!-- <v-col align="start">
                  <atoms-text class="primary--text">{{
                    (x.user.nickname && `Oleh ${x.user.nickname}`) || "-"
                  }}</atoms-text>

                  <atoms-text
                    v-if="x.fileTandaTerima && x.fileTandaTerima !== null"
                    style="cursor: pointer"
                    @click="openWindow(x.fileTandaTerima)"
                    class="red--text text-decoration-underline"
                    >Lihat tanda terima</atoms-text
                  >
                </v-col> -->
                          <v-col cols="12" lg="auto" class="shrink">
                            <atoms-text class=""> {{ $moment(startDate).format('DD MMMM YYYY') }}</atoms-text>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-5"></v-divider>
                      </div>
                      <v-container class="max-width">
                        <v-pagination
                          class="my-4"
                          v-model="historyWorkPage"
                          :length="historyWork.pagesLength"
                        ></v-pagination>
                      </v-container>
                    </div>
                    <div v-else-if="mainLoading">
                      <atoms-text>Loading...</atoms-text>
                    </div>
                    <div v-else>
                      <atoms-text>Pekerja tidak memiliki riwayat bekerja</atoms-text>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- {{  }} -->
            </v-col>
            <!-- detail -->
            <v-col v-if="!pureEdit" cols="10" class="my-4">
              <v-row>
                <v-col cols="12" class="text-left">
                  <atoms-text class="mb-4 font-weight-bold">Domisili Pekerja</atoms-text>
                  <div class="pa-4 accent rounded-lg">
                    <atoms-text>Alamat : {{ data.address && data.address.streetAddress }}</atoms-text>
                    <atoms-text>Kecamatan : {{ data.address && data.address.kecamatan }}</atoms-text>
                    <atoms-text>Kota : {{ data.address && data.address.city }}</atoms-text>
                    <atoms-text>Provinsi : {{ data.address && data.address.province }}</atoms-text>
                  </div>
                </v-col>
                <v-col v-if="detail" cols="12" class="text-left">
                  <atoms-text class="mb-4 font-weight-bold">Pengalaman Kerja</atoms-text>
                  <div class="pa-4 accent rounded-lg">
                    <atoms-text
                      >Pengalaman :
                      {{
                        (detail.experiences && detail.experiences.filter((it) => it.length > 0).toString()) || '-'
                      }}</atoms-text
                    >
                    <atoms-text
                      >Pekerjaan Sebelumnya :
                      {{
                        (detail.prefJobs && detail.prefJobs.filter((it) => it.length > 0).toString()) || '-'
                      }}</atoms-text
                    >
                    <atoms-text
                      >Referensi Kota :
                      {{
                        (detail.prefCities && detail.prefCities.filter((it) => it.length > 0).toString()) || '-'
                      }}</atoms-text
                    >
                    <atoms-text
                      >Kemampuan :
                      {{
                        (detail.skills &&
                          detail.skills
                            .filter((it) => it.name)
                            .map((it) => `${it.name} ${it.duration} `)
                            .toString()) ||
                        '-'
                      }}</atoms-text
                    >
                  </div>
                </v-col>
                <v-col v-if="data.detail" cols="12" class="text-left">
                  <atoms-text class="mb-4 font-weight-bold">Detail Pekerja</atoms-text>
                  <div class="pa-4 accent rounded-lg">
                    <div>
                      <atoms-text>Keterampilan :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.skills" :key="i">
                          {{ x.name || ':' }} Selama {{ x.duration || ':' }}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <atoms-text>Pengalaman :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.experiences" :key="i">
                          {{ x.experience || ':' }} dari
                          <span class="primary--text">{{
                            $moment(x.dateStart && x.dateStart).format('DD-MM-YYYY')
                          }}</span>
                          Sampai
                          <span class="green--text">
                            {{ $moment(x.dateEnd && x.dateEnd).format('DD-MM-YYYY') }}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <atoms-text>Preferensi Pekerjaan :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.prefJobs" :key="i">
                          {{ x }}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <atoms-text>Preferensi Kota :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.prefCities" :key="i">
                          {{ x.city || ':' }}-{{ x.province || ':' }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <!-- approved feature -->
            <v-col cols="10">
              <v-row>
                <v-col cols="12" md="6" class="order-2 order-md-1">
                  <atoms-text-field
                    label="Nama Pemilik Rekening"
                    v-model="form.accountDetails.accountName"
                    :disabled="!isEdit"
                    :rules="rules.default"
                  />
                  <atoms-text-field
                    label="Nomor Rekening"
                    :disabled="!isEdit"
                    v-model="form.accountDetails.accountNumber"
                    :rules="rules.default"
                  />
                  <atoms-text-field label="Jenis Rekening">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Jenis Rekening"
                      :items="$bank"
                      v-model="form.accountDetails.bankName"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                  <atoms-text-field label="Catatan" :disabled="!isEdit" v-model="form.accountNotes" :rules="[]" />
                  <atoms-text-field label="Tipe Pekerja">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Tipe Pekerja"
                      :items="$workerTypes"
                      v-model="form.workerType"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                </v-col>
                <v-col cols="12" md="6" class="text-left order-1 order-md-2">
                  <atoms-text class="mb-4 font-weight-bold" :disabled="!isEdit">Ringkasan</atoms-text>
                  <div :class="['d-flex justify-left']">
                    <v-checkbox class="ma-0 pa-0" :disabled="!isEdit" v-model="form.verified" />
                    <atoms-text class="text-left">Verified</atoms-text>
                  </div>
                  <div :class="['d-flex justify-left']">
                    <v-checkbox class="ma-0 pa-0" :disabled="!isEdit" v-model="form.captain" />
                    <atoms-text class="text-left">Kapten</atoms-text>
                  </div>
                  <div :class="['d-flex justify-left']">
                    <v-checkbox class="ma-0 pa-0" :disabled="!isEdit" v-model="form.blacklist" />
                    <atoms-text class="text-left">BlackList</atoms-text>
                  </div>
                  <div :class="['d-flex justify-left']">
                    <v-checkbox class="ma-0 pa-0" :disabled="!isEdit" v-model="form.isReadyToPayBPJS" />
                    <atoms-text class="text-left">Bersedia Bayar BPJS</atoms-text>
                  </div>
                  <div :class="['d-flex justify-left']">
                    <v-checkbox :disabled="!isEdit" class="ma-0 pa-0" v-model="form.resigned" />
                    <atoms-text class="text-left">Resigned</atoms-text>
                  </div>
                  <div :class="['d-flex justify-left']">
                    <v-checkbox :disabled="!isEdit" class="ma-0 pa-0" v-model="form.adminPayVerified" />
                    <atoms-text class="text-left">Sudah Bayar Admin</atoms-text>
                  </div>

                  <div :class="['d-flex justify-left']">
                    <v-checkbox
                      :disabled="
                        !isEdit ||
                        Boolean(
                          $store.state.user.data &&
                            $store.state.user.data.roles.find(
                              (_it) => _it != $roles.Super_Admin && _it != $roles.Admin && _it != $roles.Manager,
                            ),
                        )
                      "
                      class="ma-0 pa-0"
                      v-model="form.isHasUniform"
                    />
                    <atoms-text class="text-left">Sudah Memiliki Seragam</atoms-text>
                  </div>

                  <div>
                    <v-dialog
                      v-if="form.isHasUniform"
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="form.uniformPurchaseDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-label
                          ><atoms-text class="pa-2 pt-0 text-left font-weight-bold"
                            >Tanggal Kepemilikan Seragam</atoms-text
                          ></v-label
                        >
                        <v-text-field
                          class="rounded-lg"
                          :disabled="!isEdit"
                          v-model="formatDateUniform"
                          placeholder="Tanggal  Kepemilikan Seragam"
                          prepend-icon="mdi-calendar"
                          :rules="(v) => !!v || 'Harap diisi'"
                          readonly
                          background-color="accent"
                          flat
                          solo
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker v-model="form.uniformPurchaseDate" scrollable class="rounded-lg">
                        <v-spacer />
                        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(form.birthday)"> OK </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-2" />
            </v-col>
            <v-col cols="10" align="start">
              <v-row>
                <v-col cols="12" md="6">
                  <atoms-text-field
                    :disabled="!isEdit"
                    label="Nama Panjang"
                    v-model="form.fullName"
                    :rules="rules.default"
                  />
                  <atoms-text-field :disabled="!isEdit" label="KTP" v-model="form.idNumber" :rules="rules.default" />
                  <atoms-text-field
                    label="Email"
                    :disabled="!isEdit"
                    v-model="form.email"
                    :rules="[
                      (v) => !!v || 'Email harap diisi!',
                      (v) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid',
                      (v) => (!!v && this.duplicate !== v) || 'Email sudah terdaftar!',
                    ]"
                  />
                  <atoms-text-field
                    label="Minimum Gaji"
                    :disabled="!isEdit"
                    prefix="Rp."
                    v-model="form.salary"
                    :rules="rules.default"
                  />
                </v-col>
                <!-- PHOTO ID -->
                <v-col cols="12" md="6">
                  <div>
                    <atoms-image
                      class="rounded-lg"
                      :contain="true"
                      :maxH="300"
                      :src="(photoId && photoId.url) || '/media/ektp-example.webp'"
                    >
                      <v-overlay :value="true" opacity="0.5" absolute>
                        <div
                          class="pa-12"
                          :style="{
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                          }"
                        >
                          <atoms-text v-if="isEdit" @click="openUploader('uploaderId')" class="font-weight-medium"
                            ><v-icon small left> mdi-camera </v-icon>Ganti Foto</atoms-text
                          >

                          <atoms-text
                            @click="
                              photoId &&
                                photoId.url &&
                                $viewerApi({
                                  options: {
                                    toolbar: false,
                                  },
                                  images: [photoId.url],
                                })
                            "
                            class="font-weight-medium mt-4"
                            ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                          >
                        </div>
                      </v-overlay></atoms-image
                    >
                    <input ref="uploaderId" type="file" accept="image/*" class="d-none" @change="onPhotoIdChange" />
                  </div>
                  <atoms-button
                    :disabled="!isEdit"
                    @click="openUploader('uploaderId')"
                    class="primary mt-4"
                    style="width: 100%"
                    >Ganti Foto KTP</atoms-button
                  >
                </v-col>

                <v-col cols="12">
                  <atoms-text-field label="Telepon" :disabled="!isEdit" v-model="form.phone" :rules="rules.phone" />
                  <div>
                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="form.birthday" persistent width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-label
                          ><atoms-text class="pa-2 pt-0 text-left font-weight-bold">Tanggal Lahir</atoms-text></v-label
                        >
                        <v-text-field
                          class="rounded-lg"
                          :disabled="!isEdit"
                          v-model="formatDate"
                          placeholder="Tanggal Lahir"
                          prepend-icon="mdi-calendar"
                          :rules="rules.birthday"
                          readonly
                          background-color="accent"
                          flat
                          solo
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        :max="$moment().subtract(17, 'years').format('YYYY-MM-DD')"
                        v-model="form.birthday"
                        scrollable
                        class="rounded-lg"
                      >
                        <v-spacer />
                        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(form.birthday)"> OK </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                  <atoms-text-field
                    label="Tempat Lahir"
                    :disabled="!isEdit"
                    v-model="form.birthplace"
                    :rules="rules.default"
                  />
                  <atoms-text-field label="Jenis Kelamin">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Jenis Kelamin"
                      :items="Object.values($gender)"
                      v-model="form.sex"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                  <!-- {{ form.address }} -->
                  <atoms-text-field label="Provinsi">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Provinsi"
                      :items="Object.values($provinces)"
                      v-model="form.address.province"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>

                  <atoms-text-field label="Kota">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Kota"
                      :items="form.address.province ? Object.values($cities[form.address.province]) : []"
                      v-model="form.address.city"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                  <!-- <atoms-text-field label="Kecamatan">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Kecamatan"
                      :items="
                        form.address.city && $kecamatan[form.address.city]
                          ? Object.values($kecamatan[form.address.city])
                          : []
                      "
                      v-model="form.address.kecamatan"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field> -->

                  <atoms-text-field
                    label="Kecamatan"
                    :disabled="!isEdit"
                    v-model="form.address.kecamatan"
                    :rules="rules.default"
                  />
                  <atoms-text-field
                    label="Kelurahan"
                    :disabled="!isEdit"
                    v-model="form.address.kelurahan"
                    :rules="rules.default"
                  />
                  <!-- <atoms-text-field label="Kelurahan">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Kelurahan"
                      :items="form.address.city ? Object.values($kelurahan[form.address.kecamatan]) : []"
                      v-model="form.address.kelurahan"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field> -->
                  <atoms-text-field label="Pendidikan Terakhir">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Pendidikan Terakhir"
                      :items="($education && $education) || []"
                      v-model="form.education"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>

                  <atoms-text-field label="Status Pernikahan">
                    <v-autocomplete
                      :disabled="!isEdit"
                      class="rounded-lg"
                      placeholder="Status Pernikahan"
                      :items="($statusMarried && $statusMarried) || []"
                      v-model="form.statusMarried"
                      :menu-props="{ offsetY: true }"
                      :rules="rules.default"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>

                  <atoms-text-field
                    label="NPWP"
                    :disabled="!isEdit"
                    v-model="form.npwp"
                    mask="##.###.###.#-###.###"
                    :rules="[]"
                  />

                  <atoms-text-field
                    label="Nama Kerabat"
                    :disabled="!isEdit"
                    v-model="form.nextOfKin"
                    :rules="rules.default"
                  />

                  <atoms-text-field
                    label="Nomor Telepon Kerabat"
                    :disabled="!isEdit"
                    v-model="form.nextOfKinContact"
                    :rules="rules.default"
                  />

                  <atoms-text-field label="Tentang">
                    <v-textarea :disabled="!isEdit" :value="form.about" solo flat class="rounded-lg"></v-textarea>
                  </atoms-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <atoms-button
                    :disabled="!isEdit"
                    :loading="$store.state.loading"
                    type="submit"
                    :style="{ width: '100%' }"
                    class="primary"
                    >Simpan Pekerja</atoms-button
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <atoms-button
                    :disabled="!isEdit"
                    @click="reset"
                    :style="{ width: '100%' }"
                    class="primary lighten-5 primary--text"
                    >Reset Default</atoms-button
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['data', 'pureEdit', 'newWorker'],
  data() {
    return {
      isEdit: false,
      modal: null,
      photo: null,
      photoId: null,
      mainLoading: false,
      historyWorkPage: 1,
      historyWorkLimit: 10,
      historyWork: null,
      historyWorkItems: null,
      detail: null,
      form: {
        idNumber: '',
        fullName: '',
        email: '',
        phone: '',
        npwp: '',
        nextOfKin: '',
        nextOfKinContact: '',
        workerType: '',
        birthday: this.$moment().subtract(17, 'years').format('YYYY-MM-DD'),
        birthplace: '',
        salary: '',
        sex: '',
        education: '',
        statusMarried: '',
        about: '',
        blacklist: false,
        resigned: false,
        isReadyToPayBPJS: false,
        adminPayVerified: false,
        captain: false,
        verified: false,
        accountNotes: '',
        address: {
          province: null,
          city: null,
          kecamatan: null,
          kelurahan: null,
          streetAddress: null,
        },
        accountDetails: {
          accountName: '',
          accountNumber: '',
          bankName: '',
        },
        isHasUniform: false,
        uniformPurchaseDate: this.$moment().format('YYYY-MM-DD'),
      },
      duplicate: '',
      valid: true,
      rules: {
        default: [(v) => !!v || 'Harap diisi!'],
        phone: [
          (v) => !!v || 'Nomor harap diisi!',
          (
            v, ///^(62|0)8[1-9][0-9]{9,12}$/
          ) => /^\d{9,12}/.test(v) || 'Nomor tidak valid (Contoh: 6287765347111/089666347816)',
        ],
        birthday: [(v) => v !== this.$moment().format('DD-MM-YYYY') || 'Harap disesuaikan!'],
        city: [(v) => !!this.province || 'Pilih Provinsi terlebih dahulu!', (v) => !!v || 'Harap Diisi'],
        kecamatan: [(v) => !!this.city || 'Harap Pilih Kota!', (v) => !!v || 'Harap diisi!'],
      },
    }
  },
  computed: {
    formatDate: {
      set(value) {
        this.form.birthday = value
      },
      get() {
        return this.$moment(this.form.birthday).format('DD-MM-YYYY')
      },
    },
    formatDateUniform: {
      set(value) {
        this.form.uniformPurchaseDate = value
      },
      get() {
        return this.$moment(this.form.uniformPurchaseDate).format('DD-MM-YYYY')
      },
    },
  },
  watch: {
    'form.salary': function (newValue) {
      const result = this.$thousandSeparator(String(newValue))
      this.$nextTick(() => (this.form.salary = result))
    },
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'

    if (this.pureEdit) {
      this.isEdit = true
    } else {
      this.isEdit = false
    }

    if (!this.data) {
      this.onClose()
    } else {
      this.reset()
    }
  },
  methods: {
    async getHistory() {
      this.mainLoading = true
      this.historyWork = await this.$api.get('QuotationItems/get', {
        params: {
          jsonQuery: JSON.stringify({
            workerIds: {
              $in: [this.data._id],
            },
            pipeline: [
              {
                $lookup: {
                  from: 'Clients',
                  localField: 'clientId',
                  foreignField: '_id',
                  as: 'client',
                },
              },
              {
                $lookup: {
                  from: 'Areas',
                  localField: 'areaId',
                  foreignField: '_id',
                  as: 'area',
                },
              },
            ],
          }),
          page: 1,
          limit: this.historyWorkLimit,
        },
      })

      this.historyWorkItems = this.historyWork?.result?.map((it) => {
        return {
          ...it,
          client: it.client?.[0],
        }
      })

      const filterDistinct = this.historyWorkItems
        ? new Set(this.historyWorkItems?.flatMap((it) => it.workerIds))
        : null

      this.historyWorkItems =
        (filterDistinct && [...filterDistinct.values()]?.filter((it) => it == this.data._id))?.map((it) => {
          const findClient = this.historyWorkItems?.find((x) => x.workerIds.includes(it))
          return findClient
        }) || null

      this.mainLoading = false
    },
    onClose() {
      document.documentElement.style.overflow = 'auto'
      this.$emit('close')
      this.$emit('closed')
    },
    async getWorkers(_id) {
      this.$store.commit('SET', { loading: true })
      try {
        //* fetch worker data
        const data = (
          await this.$store.dispatch('worker/getAll', {
            query: {
              _id,
            },
            page: 1,
            limit: 1,
            returnOnly: true,
          })
        )?.[0]
        if (data?.detail) {
          this.detail = data.detail
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async reset() {
      await this.getWorkers()
      for (const [key, value] of Object.entries(this.form)) {
        if (typeof this.form[value] !== 'object') this.form[key] = this.data?.[key] || this.form[key]
      }
      for (const [key, value] of Object.entries(this.form.accountDetails)) {
        if (typeof this.form?.accountDetails?.[value] !== 'object')
          this.form.accountDetails[key] = this.data?.accountDetails?.[key] || this.form?.accountDetails?.[key]
      }
      if (this.form.address) {
        for (const [key, value] of Object.entries(this.form.address)) {
          if (typeof this.form?.address?.[value] !== 'object')
            this.form.address[key] = this.data?.address?.[key]?.trim() || this.form?.address?.[key]?.trim()
        }
      }
      // console.log(this.form.address)
      this.form.birthday = this.$moment(this.data?.birthday).format('YYYY-MM-DD')
      this.form.sex = this.data?.sex == 'Male' ? this.$gender.Male : this.$gender.Female

      this.photo = {
        url: this.data?.photo,
      }
      this.photoId = {
        url: this.data?.photoId,
      }
    },
    openUploader(constant) {
      if (this.$refs[constant]) {
        this.$refs[constant]?.click()
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async onPhotoIdChange(payload) {
      payload = payload.target.files[0]
      if (payload && payload.size > 3000000) {
        this.$dialog.showDialog({
          title: 'Perhatian',
          content: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (payload) {
        this.photoId = {
          image: payload,
          url: URL.createObjectURL(payload),
        }
        URL.revokeObjectURL(payload) // free memory
      } else {
        this.photoId = null
      }
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.form.email || '', $options: 'i' }
      try {
        if (this.form.email && this.form.email !== this.data?.email) {
          this.form.email = this.form.email.trim()
          const { result: worker } = await this.$api.get(`Workers/count`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          const { result: user } = await this.$api.get(`Users/isExist`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          if (worker > 0 || user) {
            this.duplicate = this.form.email
          } else {
            this.duplicate = ''
          }
        }

        if (!this.form.isHasUniform) {
          this.form.uniformPurchaseDate = null
        }

        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      try {
        if (this.valid) {
          let _body = {
            ...this.data,
            ...(this.form
              ? Object.assign(
                  {},
                  ...Object.entries(this.form).map(([key, value]) => ({
                    [key]: value || this.data?.[key],
                  })),
                )
              : {}),
            ...(this.form?.address
              ? Object.assign(
                  {},
                  ...Object.entries(this.form?.address).map(([key, value]) => ({
                    [key]: value || this.data?.address?.[key],
                  })),
                )
              : {}),
            ...(this.form?.accountDetails
              ? Object.assign(
                  {},
                  ...Object.entries(this.form?.accountDetails).map(([key, value]) => {
                    return {
                      [key]: value || this.data?.accountDetails?.[key],
                    }
                  }),
                )
              : {}),
            // address: {
            //   province: this.form.address.province,
            //   city: this.form.address.city,
            //   kecamatan: this.form.address.kecamatan,
            //   kelurahan: this.form.address.kelurahan,
            //   streetAddress: this.form.address.streetAddress,
            // },
            // accountDetails: {
            //   accountName: this.form.accountDetails.accountName,
            //   accountNumber: this.form.accountDetails.accountNumber,
            //   bankName: this.form.accountDetails.bankName,
            // },
            captain: this.form.captain || false,
            blacklist: this.form.blacklist || false,
            resigned: this.form.resigned || false,
            isReadyToPayBPJS: this.form.isReadyToPayBPJS || false,
            adminPayVerified: this.form.adminPayVerified || false,
            updatedBy: this.$store.state.user?.email,
            salary: Number(this.$removeSeparator(this.form.salary)) || this.data?.salary,
            sex: this.form.sex === this.$gender.Male ? 'Male' : 'Female' || this.data?.sex,
            photo:
              this.photo && this.photo.image
                ? await this.$uploadFile(
                    this.photo.image,
                    `Worker/${this.idNumber || this.data?.idNumber}/Profil`,
                    `photo`,
                  )
                : this.data?.photo,
            photoId:
              this.photoId && this.photoId.image
                ? await this.$uploadFile(
                    this.photoId.image,
                    `Worker/${this.idNumber || this.data?.idNumber}/Profil`,
                    `photoId`,
                  )
                : this.data?.photoId,
          }

          if (this.form.idNumber != this.data.idNumber) {
            _body.idNumber = this.form.idNumber ?? this.data.idNumber
          } else {
            delete _body.idNumber
          }

          await this.$store.dispatch('worker/update', _body)

          this.$nextTick(() => {
            this.$refs.form.resetValidation()
            this.$refs.form.reset()
            this.$showDialog({
              title: 'Berhasil',
              body: `Pekerja ${this.data?.fullName} baru berhasil diperbarui`,
            })
            this.onClose()
          })
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        alert(error?.message ?? error?.toString())
        Promise.reject(error)
      }
    },
  },
}
</script>
